.search-page {
  width: 100%;
  height: 100vh;
  /* background-color: rgb(18, 127, 222); */
  /* overflow: hidden; */
}
.inner-search-page {
  max-width: 1920px;
  height: 100%;
  /* background-color: aqua; */
  margin: 0 auto;
}
.cros-div {
  width: 90%;
  height: 100px;
  /* background-color: red; */
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: end;
}
.cros-div i {
  font-size: 30px;
}

.input-sp-div {
  width: 70%;
  height: 600px;
  /* background-color: aqua; */
  margin: 0 auto;
  /* border: 1px solid; */
}
.i-d {
  width: 90%;
  /* background-color: aqua; */
}
.i-d input {
  /* width: 88.8%; */
  width: 684px;
  border: none;
  border-bottom: 1px solid #9a9a9a;
  padding: 10px 30px;
  position: relative;
  font-size: 24px;
  color: #666666;
  font-weight: 100;
}
.i-d input::placeholder {
  letter-spacing: 2px;
  font-size: 20px;
}
.i-d i {
  position: absolute;
  z-index: 9999;
  /* top: -30px; */
  font-size: 20px;
  /* left: 0px; */
  left: 183px;
  top: 115px;
  color: #ee5d6b;
}
.i-d input:focus {
  outline: none;
}

.input-sp-div p {
  /* color: #cfcfcf; */
  font-size: 14px;
  letter-spacing: 1px;
  cursor: default;
}
.input-sp-div hr {
  width: 745px;
  border: 1px solid #d0cece;
}

@media (max-width: 900px) {
  .i-d i {
    left: 135px;
  }
  .input-sp-div hr {
    width: 545px;
  }
  .i-d input {
    width: 485px;
  }
}
@media (max-width: 800px) {
  .i-d i {
    left: 120px;
  }
}
@media (max-width: 700px) {
  .i-d i {
    left: 100px;
  }
}
@media (max-width: 646px) {
  .i-d i {
    left: 100px;
  }
  .input-sp-div hr {
    width: 525px;
  }
  .i-d input {
    width: 465px;
  }
}
@media (max-width: 622px) {
  .input-sp-div hr {
    width: 505px;
  }
  .i-d input {
    width: 450px;
  }
  .i-d input::placeholder {
    letter-spacing: 1px;
  }
}
@media (max-width: 590px) {
  .input-sp-div hr {
    width: 490px;
  }
  .i-d input {
    width: 430px;
  }
  .i-d input::placeholder {
    letter-spacing: 1px;
    font-size: 18px;
  }
  .i-d i {
    left: 90px;
  }
}
@media (max-width: 530px) {
  .input-sp-div hr {
    width: 390px;
  }
  .i-d input {
    width: 330px;
  }
  .i-d input::placeholder {
    letter-spacing: 1px;
    font-size: 16px;
  }
  .i-d i {
    left: 80px;
  }
}
@media (max-width: 466px) {
  .input-sp-div hr {
    width: 370px;
  }
  .i-d input {
    width: 310px;
    font-size: 18px;
  }
  .i-d input::placeholder {
    letter-spacing: 0px;
    font-size: 14px;
  }
  .i-d i {
    left: 70px;
    top: 112px;
    font-size: 18px;
  }
}
@media (max-width: 440px) {
  .input-sp-div hr {
    width: 330px;
  }
  .i-d input {
    width: 273px;
    font-size: 18px;
  }
  .i-d input::placeholder {
    letter-spacing: 0px;
    font-size: 14px;
  }
  .i-d i {
    left: 70px;
    top: 112px;
    font-size: 18px;
  }
}
@media (max-width: 396px) {
  .input-sp-div hr {
    width: 310px;
  }
  .i-d input {
    width: 253px;
    font-size: 18px;
  }
  .i-d input::placeholder {
    letter-spacing: 0px;
    font-size: 12px;
  }
  .i-d i {
    left: 50px;
    top: 112px;
    font-size: 18px;
  }
  .input-sp-div {
    margin-left: 45px;
  }
}

.linksss  {
  text-decoration: none;

}
.hr-2{
  margin-left: -40px;
}
