.ln-container {
  width: 100%;
  min-height: 600px;
  /* background-color: red; */
}
.inner-ln-container {
  max-width: 1920px;
  height: 100%;
  /* background-color: #f7e5e5; */
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}
.inner-ln-container h2 {
  text-align: center;
  letter-spacing: 1px;
  font-size: 35px;
  color: #630000;
  font-weight: 700;
}
.card-div {
  width: 90%;

}
.carousel{
  width: 100%;
  height: 100%;
  /* background-color: red; */
  display: flex;
  /* gap: 20px; */
}
.card-second {
  width: 80%;
  height: 80%;
  
  /* background-color: aqua; */
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
/*  */
.card-box {
  width: 300px;
  height: 400px;
  background-color: white;
  border: 1px solid rgb(200, 191, 191);
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
  border-radius: 5px;
  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; */
  /* margin-left: 5%; */
  cursor: pointer;
}

.card-box img {
  width: 300px;
  height: 200px;
}
.card-box-div {
  padding: 0px 10px;
}
.card-box h3 {
  color: #630000;
}
.card-box i {
  font-size: 12px;
  /* margin-top: -100px; */
}
.card-box-div p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5; /* Number of lines to show */
  -webkit-box-orient: vertical;
}
@media (max-width: 500px) {
  /* .card-box {
    width: 200px;
    height: 300px;
  } */
  .card-box-div p {
    font-size: 13px;
  }
}
@media (max-width: 448px) {
  /* .card-box {
    width: 180px;
    height: 260px;
  } */
  .card-box-div p {
    font-size: 13px;
  }
  .card-box-div {
    padding: 5px 10px;
  }
  .card-box-div h4 {
    margin-top: -10px;
  }
}
@media (max-width: 418px) {
  /* .card-box {
    width: 160px;
    height: 240px;
  } */
  .card-box-div p {
    font-size: 12px;
  }
  .card-box-div {
    padding: 10px 10px;
  }
  .card-box-div h4 {
    font-size: 14px;
    /* margin-top: -5px; */
  }
}
@media (max-width: 350px) {
  
  .card-box-div p {
    font-size: 10px;
  }
  .card-box-div {
    padding: 0px 10px;
  }
  .card-box-div h4 {
    font-size: 12px;
    margin-top: 5px;
  }
}

@media (max-width: 978px) {
  .carousel {
    .react-multiple-carousel__arrow--right {
      margin-top: 350px;
      /* right: calc(-9% + 100px); */
      /* margin-left: 900px; */
      z-index: 253645;
    }
  }
  .carousel {
    .react-multiple-carousel__arrow--left {
      margin-top: 350px;
      
      z-index: 2;
    }
  }
}

.blog-container {
  width: 100%;
  min-height: 500px;
  background-color: #f7e5e5;
}
.inner-blog-container {
  max-width: 1920px;
  height: 100%;
  margin: -18px auto;
  /* background-color: #f7e5e5; */
  display: flex;
  justify-content: center;
}
.width-blog-container {
  width: 80%;
  padding: 20px 0px;
  text-align: justify;
  text-decoration: none;
  color: #630000;
}

/* @media(max-width:1036px){
  .card-second{
    width: 100%;
    margin-left: 80px;
    gap: 30px;
  }
} */