.success-container {
  width: 100%;
  height: 400px;
  background-color: aqua;
}
.inner-success-coantiner {
  max-width: 1920px;
  height: 100%;
  background-color: #f7e5e5;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.inner-success-coantiner p {
  text-align: center;
  font-size: 30px;
  color: #630000;
  line-height: 60px;
}
.width-su-container {
  width: 80%;
}
.sp {
  font-size: 31px;
}

@media (max-width: 580px) {
  .success-container {
    height: 400px;
    background-color: aqua;
  }
  .inner-success-coantiner p {
    text-align: justify;
    font-size: 25px;
    padding: 20px;
    line-height: 40px;
  }
}
@media (max-width: 440px) {
  .inner-success-coantiner p {
    font-size: 22px;
  }
}

@media (max-width: 390px) {
  .inner-success-coantiner p {
    font-size: 20px;
  }
}
@media (max-width: 360px) {
  .inner-success-coantiner p {
    font-size: 18px;
  }
}

@media (max-width: 510px) {
  .inner-success-coantiner p {
    font-size: 18px;
  }
  .sp {
    font-size: 20px;
  }
}
