/* Aboutuscss.css */

.abouts {
  background-color: #f7e5e5;
  color: #630000;
  /* padding-top: 70px;  */
  /* padding-bottom: 40px; */
}

.about-page {
  display: flex;
  justify-content: space-around;
  margin-bottom: 10px;
}

.about-page h1 {
  font-size: 30px;
}

.about-page p {
  font-size: 18px;
}

.about-content {
  width: 500px;
  margin-left: 60px;
  text-align: justify;
}

.about-content h1,
.about-content p {
  color: #630000;
}

.about-image,
.about-image1 {
  position: relative;
  bottom: 50px; /* Adjust as needed */
}

/* Media queries for smaller devices */
@media (max-width: 800px) {
  .about-page {
    flex-direction: column;
    /* flex-direction: column-reverse; */
    align-items: center;
    gap: 50px;
  }
  .ap-2{
        flex-direction: column-reverse;
  }

  .about-content {
    width: 90%;
    margin-left: 0;
    margin-top: -20px; /* Adjust as needed */
  }

  .about-image img {
    width: 100%; /* Make images responsive */
    height: auto; /* Maintain aspect ratio */
  }
}

@media (max-width: 600px) {
  .about-content {
    width: 90%;
    margin-left: 50px;
  }

  .about-image img {
    width: 100%; /* Make images responsive */
    height: auto; /* Maintain aspect ratio */
  }
}
@media (max-width: 800px) {
  .about-content p {
    /* width: 90%; */
    position: relative;
    left: -8%;
  }
}
@media(max-width:500px){
  .about-content{
    margin-top: -70px;
  }
}
.About2 {
  display: flex;
  flex-direction: column;
  background-color: #630000;
}

.abouticon1 h2 {
  color: #630000;
}
.abouticon2 h2 {
  color: #630000;
}

.abouticon1,
.abouticon2 {
  background: #f7e5e5;
  width: 70%; /* Adjusted width for responsiveness */
  padding: 24px;
  /* position: relative; */

  border-radius: 10px;
}

.abouticon1 p,
.abouticon2 p {
  /* width: 100%; */
  /* position: relative; */
  /* bottom: 15px; */
  text-align: justify;
  color: #630000;
  font-size: 18px;
}

.abouticon1-one,
.abouticon2-one {
  width: 100%;
  display: flex;
  justify-content: center; /* Adjusted padding for responsiveness */

  margin-top: 50px;
}

/* Hover animation */
.fa-solid.fa-eye:hover,
.fa-solid.fa-bullseye:hover {
  transform: scale(1.1) rotateY(180deg);
}

.fa-solid.fa-eye,
.fa-solid.fa-bullseye {
  transition: transform 0.9s ease;
}

.abouts {
  max-width: 1920px;
  height: 100%;

  margin: 0 auto;

  background-color: #f7e5e5;
  color: white;
}

.About-one {
  width: 100%;
  min-height: 340px;
}



.About-two {
  width: 100%;
  min-height: 340px;
  background-color: #630000;
}
.About2 {
  max-width: 1920px;
  height: 100%;

  margin: 0 auto;
  display: flex;
  background-color: #630000;
  justify-content: center;
  padding-bottom: 20px;
}
/* .about-image1{
  background-color: red;
} */
@media (max-width: 386px) {
  .about-image1 img {
    width: 300px;
    height: 300px;
  }
  /* .about-content p {
    margin-left: -20px;
  } */
}
@media (max-width: 552px) {
  .about-image1 img {
    width: 450px;
    height: 450px;
  }
}

@media (max-width: 470px) {
  .about-image1 img {
    width: 420px;
    height: 400px;
  }
}
@media (max-width: 434px) {
  .about-image1 img {
    width: 380px;
    height: 380px;
  }
}
@media (max-width: 398px) {
  .about-image1 img {
    width: 360px;
    height: 340px;
  }
}
@media (max-width: 384px) {
  .about-image1 img {
    width: 320px;
    height: 320px;
  }
}
@media (max-width: 344px) {
  .about-image1 img {
    width: 300px;
    height: 300px;
  }
}
