.loader-1 {
  border: 5px solid #f3f3f3;
  border-top: 5px solid #630000;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-left: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
