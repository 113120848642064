.sign-in-container {
  width: 100%;
  height: 480px;
  /* background-color: aqua; */
}
.inner-sign-in-container {
  max-width: 1920px;
  height: 100%;
  background-color: #f7e5e5;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  color: #630000;
}
.w-sign-in-container {
  width: 28%;
  /* height: 100%; */
  text-align: center;
  /* background-color: #fff; */
  /* background-color: #630000; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  /* gap: 20px; */
  /* flex-direction: column;
  align-items: center;
  justify-content: space-around; */
}
.mt {
  margin-top: -10px;
  font-size: 18px;
  width: 320px;
}
.w-sign-in-container h2 {
  font-size: 32px;
  font-weight: 500;
}
.w-sign-in-container img {
  width: 260px;
}
.sign-in-button-div {
  width: 100%;
  height: 120px;
  /* background-color: aqua; */
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 10px;
}
/* 
.button {
  display: block;
  position: relative;
  width: 56px;
  height: 56px;
  margin: 0;
  overflow: hidden;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  border: 0;
}

.button:before,
.button:after {
  content: "";
  position: absolute;
  border-radius: 50%;
  inset: 7px;
}

.button:before {
  border: 4px solid #f0eeef;
  transition: opacity 0.4s cubic-bezier(0.77, 0, 0.175, 1) 80ms,
    transform 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955) 80ms;
}

.button:after {
  border: 4px solid #96daf0;
  transform: scale(1.3);
  transition: opacity 0.4s cubic-bezier(0.165, 0.84, 0.44, 1),
    transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  opacity: 0;
}

.button:hover:before,
.button:focus:before {
  opacity: 0;
  transform: scale(0.7);
  transition: opacity 0.4s cubic-bezier(0.165, 0.84, 0.44, 1),
    transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.button:hover:after,
.button:focus:after {
  opacity: 1;
  transform: scale(1);
  transition: opacity 0.4s cubic-bezier(0.77, 0, 0.175, 1) 80ms,
    transform 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955) 80ms;
}

.button-box {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.button-elem {
  display: block;
  width: 20px;
  height: 20px;
  margin: 17px 18px 0 18px;
  transform: rotate(180deg);
  fill: #f0eeef;
}

.button:hover .button-box,
.button:focus .button-box {
  transition: 0.4s;
  transform: translateX(-56px);
} */
.w-sign-in-container button {
  width: 340px;
  height: 65px;
  font-size: 20px;
  color: #fff;
  background-color: #630000;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.animated-button {
  position: relative;
  display: flex;
  align-items: center;
  gap: 4px;
  width: 400px;
  padding: 10px 36px;
  border: 4px solid;
  border-color: transparent;
  font-size: 16px;
  background-color: #630000;
  border-radius: 5px;
  font-weight: 600;
  color: #fff;
  box-shadow: 0 0 0 2px #ffffff;
  cursor: pointer;
  overflow: hidden;

  transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
}

.animated-button svg {
  position: absolute;
  width: 24px;
  fill: #fff;
  z-index: 9;
  transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
}

.animated-button .arr-1 {
  right: 16px;
}

.animated-button .arr-2 {
  left: -25%;
}

.animated-button .circle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
  background-color: #630000;
  border-radius: 50%;
  opacity: 0;
  transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
}

.animated-button .text {
  position: relative;
  z-index: 1;
  transform: translateX(-12px);
  transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
}

.animated-button:hover {
  box-shadow: 0 0 0 12px transparent;
  color: #fff;
  border-radius: 12px;
}

.animated-button:hover .arr-1 {
  right: -25%;
}

.animated-button:hover .arr-2 {
  left: 16px;
}

.animated-button:hover .text {
  transform: translateX(12px);
}

.animated-button:hover svg {
  fill: #fff;
}

.animated-button:active {
  scale: 0.95;
  box-shadow: 0 0 0 4px #630000;
}

.animated-button:hover .circle {
  width: 220px;
  height: 220px;
  opacity: 1;
}

/* button {
    display: flex;
    height: 3em;
    width: 100px;
    align-items: center;
    justify-content: center;
    background-color: #eeeeee4b;
    border-radius: 3px;
    letter-spacing: 1px;
    transition: all 0.2s linear;
    cursor: pointer;
    border: none;
    background: #fff;
   }
   
   button > svg {
    margin-right: 5px;
    margin-left: 5px;
    font-size: 20px;
    transition: all 0.4s ease-in;
   }
   
   button:hover > svg {
    font-size: 1.2em;
    transform: translateX(-5px);
   }
   
   button:hover {
    box-shadow: 9px 9px 33px #d1d1d1, -9px -9px 33px #ffffff;
    transform: translateY(-2px);
   } */

/* <button>
  <svg height="16" width="16" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 1024 1024"><path d="M874.690416 495.52477c0 11.2973-9.168824 20.466124-20.466124 20.466124l-604.773963 0 188.083679 188.083679c7.992021 7.992021 7.992021 20.947078 0 28.939099-4.001127 3.990894-9.240455 5.996574-14.46955 5.996574-5.239328 0-10.478655-1.995447-14.479783-5.996574l-223.00912-223.00912c-3.837398-3.837398-5.996574-9.046027-5.996574-14.46955 0-5.433756 2.159176-10.632151 5.996574-14.46955l223.019353-223.029586c7.992021-7.992021 20.957311-7.992021 28.949332 0 7.992021 8.002254 7.992021 20.957311 0 28.949332l-188.073446 188.073446 604.753497 0C865.521592 475.058646 874.690416 484.217237 874.690416 495.52477z"></path></svg>
  <span>Back</span>
</button> */

/*  sign-in page two css start here */

.s-i-c-2 {
  height: 500px;
}

.w-sign-in-div-1 h3 {
  font-size: 40px;
}
.w-sign-in-div-1 p {
  text-align: center;
}
.w-sign-in-div-2 {
  background-color: #630000;
  height: 350px;
}
/* .w-sign-in-div-2{
  display: flex;
  flex-direction: column;
 
  gap: 20px;
} */
.email {
  height: 30px;
  /* background-color: #f01b1b; */
  display: flex;
  align-items: center;
  gap: 20px;
  /* justify-content: center; */
}
.email input {
  width: 300px;
  padding: 10px;
}
.password {
  height: 30px;
  /* background-color: #630000; */
  display: flex;
  align-items: center;
  gap: 20px;
}
.password input {
  width: 300px;
  padding: 10px;
}

/* .i-f{
  margin-left: "-235px"
} */

.n-s-p {
  margin-left: -230px;
}

.i-f input {
  color: #630000;
  height: 50px;
}
.labelline-2 {
  background-color: #f7e5e5;
  color: #630000;
  margin-top: -5px;
}
.i-f input:focus,
.i-f input:valid {
  border: 2px solid #630000;
}
.i-f input:valid + .fix-2 {
  color: #822222;
  line-height: 30px;
  transform: translate(-15px, -16px) scale(0.88);
  z-index: 99999;
}

.i-f input:focus + .labelline,
.i-f input:valid .labelline {
  color: #630000;
  line-height: 30px;
  transform: translate(-15px, -16px) scale(0.88);
  z-index: 99999;
}

.w-sign-in-div-3 {
  height: 250px;
  width: 90%;
  /* background-color: green; */
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 20px;
}
.btn-container-s button {
  width: 120px;
  padding: 10px 0px;
  display: flex;
  justify-content: center;
  gap: 10px;
  border-radius: 10px;
  border: none;
  background-color: #630000;
  color: #f7e5e5;
  cursor: pointer;
}
.d-s-hide {
  display: none;
}
@media (max-width: 900px) {
  .s-i-c-2 {
    height: 550px;
  }
  .w-sign-in-div-1 h3 {
    font-size: 40px;
    text-align: center;
  }
  .n-s-p {
    margin-left: -210px;
  }
  .n-s-p input {
    width: 360px;
  }
}

@media (max-width: 656px) {
  .s-i-c-2 {
    height: 600px;
  }
  .w-sign-in-div-1 p {
    font-size: 25px;
  }
}
@media (max-width: 540px) {
  .s-i-c-2 {
    height: 540px;
  }
  .w-sign-in-div-1 h3 {
    font-size: 30px;
  }
  .w-sign-in-div-2 {
    margin-top: 30px;
  }
  .w-sign-in-div-3 {
    margin-top: -35px;
  }
  .w-sign-in-div-1 p {
    text-align: justify;
  }
}

@media (max-width: 468px) {
  .text {
    font-size: 10px;
  }
  .arr-2,
  .arr-1 {
    display: none;
  }
}

@media (max-width: 448px) {
  .n-s-p input {
    width: 270px;
  }
  .n-s-p {
    margin-left: -170px;
  }
  /* .sign-in-button-div {
    display: none;
  } */
  .hide-sign-div {
    display: none;
  }
  .d-s-hide {
    display: block;
    display: flex;
  }
  .w-sign-in-div-1 h3 {
    font-size: 27px;
  }
  .w-sign-in-div-1 p {
    font-size: 22px;
  }
  .d-s-hide button {
    font-size: 20px;
    padding: 0px 30px;
  }
}
/* \\\ sign in page css 3 start here */

.w-sign-in-btn-container {
  width: 90%;
  height: 400px;
  /* background-color: #fff; */
  display: flex;
  justify-content: end;
}
.w-sign-heading h3 {
  font-size: 40px;
}
.w-sign-in-btn-container .div-b-pf .btn-s-3 {
  width: 140px;
}
.w-sign-in-btn-container .div-b-pf .btn-s-3-1 {
  width: 100px;
}
/* 
.w-sign-in-btn-container.pf .btn-s-4{
  width: 20px;
} */
.div-for-button {
  width: 900px;
  height: 30px;
  /* background-color: #fff; */
  /* margin-left: -300px; */
}
.div-for-button button {
  width: 130px;
  border-radius: 30px;
  color: #fff;
}
.div-for-button {
  display: flex;
  gap: 30px;
}
@media (max-width: 500px) {
  .w-sign-heading .hi-ha {
    font-size: 35px;
  }
  .w-sign-heading .para-pa {
    font-size: 20px;
    line-height: 34px;
  }
}
@media (max-width: 420px) {
  .w-sign-heading .hi-ha {
    font-size: 30px;
  }
}
@media (max-width: 606px) {
  .pf-hode {
    display: none;
  }
  .div-for-button button {
    background-color: #630000;
    border: none;
    /* padding: 10px 30px; */
  }
}

/* / css start her four */

.sigin-in-container-4 {
  width: 100%;
  height: 460px;
  /* background-color: #f7e5e5; */
}
/* .or{
  height: 500px;
} */
.inner-sign-in-container-4 {
  max-width: 1920px;
  height: 100%;
  background-color: #f7e5e5;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}
.w-sigin-in-conatiner-4 {
  width: 40%;
  height: 100%;
  /* background-color: #fff; */
  /* text-align: center; */
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* align-items: center; */
  gap: 20px;
}
.for-heading-container-4 {
  width: 100%;
  height: 70%;
  /* background-color: aqua; */
  color: #630000;
}
.fa-circle-check {
  margin-top: 30px;
  margin-left: 190px;
}
.for-heading-container-4 i {
  font-size: 50px;
  /* margin-left: 40px; */
}
.for-heading-container-4 h3 {
  font-size: 32px;
  font-weight: 500;
  width: 410px;
}
.style {
  margin-left: 60px;
}
.for-heading-container-4 p {
  font-size: 18px;
  /* margin-top: 20px; */
}
.inner-p {
  width: 400px;
}
.div-class-name {
  /* background-color: #630000; */
  height: 40px;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  margin-left: 60px;
  gap: 10px;
}
.btn-sign-3 {
  margin-left: 40px;
  /* margin-top: 0px; */
}
.div-class-name i {
  font-size: 18px;
  /* margin-top: -5px; */
}

.for-button-container-4 {
  width: 100%;
  height: 20%;
  /* background-color: aquamarine; */
  display: flex;
  /* align-items: center; */
  /* justify-content: end; */
  gap: 10px;
}
.for-button-container-4 button {
  width: 130px;
  height: 45px;
  /* padding: 10px 30px; */
  border-radius: 20px;
  border: none;
  background-color: #630000;
  color: #f7e5e5;
  cursor: pointer;
}
.for-button-container-4.bt-div {
  background-color: #630000;
}

@media (max-width: 538px) {
  .for-button-container-4 button {
    margin-top: 40px;
  }
}
@media (max-width: 514px) {
  .for-button-container-4 button {
    font-size: 13px;
  }
}
@media (max-width: 494px) {
  .for-heading-container-4 h3 {
    font-size: 35px;
  }
  .for-button-container-4 button {
    /* padding: 10px 90px; */
    font-size: 11px;
  }
}
@media (max-width: 470px) {
  .for-button-container-4 {
    gap: 10px;
  }
  .for-heading-container-4 h3 {
    width: 390px;
    /* background-color: #630000; */
  }
}
@media (max-width: 426px) {
  .for-heading-container-4 h3 {
    font-size: 30px;
  }
  .for-button-container-4 {
    gap: 5px;
  }
  .for-heading-container-4 p {
    font-size: 15px;
  }
  .for-button-container-4 button {
    width: 400px;
    padding: 10px 0px;
    font-size: 10px;
  }
}
/* \\\ page3 media query start here */
@media (max-width: 824px) {
  .or {
    height: 500px;
  }
  .three {
    margin-top: 30px;
  }
}
@media (max-width: 686px) {
  .or {
    height: 500px;
  }
  .three {
    margin-top: 50px;
  }
}
@media (max-width: 650px) {
  .para-size-3 p {
    font-size: 25px;
  }
  .three {
    margin-top: 20px;
  }
  .or {
    height: 450px;
  }
}
@media (max-width: 570px) {
  .para-size-3 h3 {
    font-size: 30px;
  }
}
@media (max-width: 414px) {
  .para-size-3 h3 {
    font-size: 22px;
  }
  .para-size-3 p {
    font-size: 22px;
  }
  .three {
    margin-top: -20px;
  }
}
@media (max-width: 436px) {
  .three button {
    padding: 10px 0px;
    width: 200px;
  }
}

/* // =======> SIGN IN PAGE FIVE CSS START HERE <=========== */

@media (max-width: 726px) {
  /* .sign-in-container {
    height: 450px;
  } */
}

@media (max-width: 544px) {
  /* .sign-in-container {
    height: 500px;
  } */
}
@media (max-width: 710px) {
  .w-sign-in-container h2 {
    font-size: 35px;
  }
  .w-sign-in-container p {
    font-size: 30px;
  }
}
@media (max-width: 628px) {
  .w-sign-in-container h2 {
    font-size: 30px;
  }
  .w-sign-in-container p {
    font-size: 20px;
  }
}

.thre-two {
  margin-top: -40px;
  margin-left: 25px;
}
.para-size-3 {
  margin-top: 50px;
  margin-left: 30px;
}
.line-height {
  margin-top: 30px;
}

@media (max-width: 838px) {
  .w-sign-in-container h2 {
    width: 340px;
    /* background-color: #fff; */
  }
}
@media (max-width: 876px) {
  .thre-two {
    width: 400px;
    /* background-color: #fff; */
  }
}
@media (max-width: 652px) {
  .thre-two {
    margin-top: 60px;
    display: flex;
    align-items: center;
  }
  .w-s-i-c-4 {
    margin-top: -50px;
    margin-left: -180px;
  }
}
@media (max-width: 622px) {
  .s-i-c-4 {
    height: 500px;
  }
}
@media (max-width: 538px) {
  .thre-two {
    margin-top: 20px;
  }
  .inner-p-2 {
    width: 340px;
  }
}
@media (max-width: 498px) {
  .thre-two {
    width: 350px;
    /* background-color: #fff; */
  }
}
@media (max-width: 482px) {
  .para-size-3 .inner-h {
    font-size: 35px;
  }
}
@media (max-width: 454px) {
  .para-size-3 .inner-h {
    font-size: 30px;
    width: 350px;
  }
  .inner-p {
    width: 355px;
  }
}
@media (max-width: 428px) {
  .thre-two {
    margin-left: -0px;
  }
}

@media (max-width: 414px) {
  .inner-p-2 {
    width: 320px;
  }
}
@media (max-width: 392px) {
  .para-size-3 .inner-h {
    width: 315px;
    font-size: 25px;
    margin-left: -20px;
  }
  .inner-p {
    margin-left: -20px;
  }
  .inner-p-2 {
    margin-left: -20px;
  }
  .thre-two {
    margin-left: -10px;
  }
}
@media (max-width: 380px) {
  .thre-two button {
    width: 100px;
    margin-left: 10px;
  }
}
@media (max-width: 364px) {
  .thre-two button {
    width: 90px;
    height: 40px;
    margin-left: 15px;
  }
}
@media (max-width: 358px) {
  .inner-p {
    width: 310px;
  }
}
@media (max-width: 786px) {
  .w-s-i-c-4 {
    margin-left: -170px;
  }
}

@media (max-width: 968px) {
  .wape-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}

@media (max-width: 946px) {
  .ss-4 {
    margin-top: 20px;
  }
}
@media (max-width: 866px) {
  .ss-4 {
    width: 400px;
    /* background-color: #fff; */
  }
}
@media (max-width: 790px) {
  .div-class-name {
    width: 400px;
    /* padding:0px 100px; */
    /* background-color: #822222; */
  }
}
@media (max-width: 786px) {
  .w-w-s-4 {
    margin-left: -150px;
  }
  .fa-circle-check {
    margin-left: 240px;
  }
}
@media (max-width: 640px) {
  .w-w-s-4 {
    margin-left: -200px;
  }
  .ss-4 {
    margin-left: 60px;
  }
}
@media (max-width: 566px) {
  .w-w-s-4 {
    margin-left: -270px;
  }
  .ss-4 {
    margin-left: 60px;
  }
}
@media (max-width: 538px) {
  .w-w-s-4 {
    margin-top: -40px;
  }
}
@media (max-width: 494px) {
  .w-w-s-4 {
    margin-left: -300px;
  }
}
@media (max-width: 466px) {
  .div-class-name {
    width: 350px;
  }
  .ss-4 button {
    width: 110px;
  }
  .ss-4 {
    width: 350px;
  }
}
@media (max-width: 418px) {
  .wape-content {
    gap: 0px;
  }
}
@media (max-width: 392px) {
  .wape-content {
    gap: 0px;
  }
  .ss-4 {
    width: 300px;
  }
  .ss-4 button {
    width: 100px;
  }
}
@media (max-width: 478px) {
  .for-heading-container-4 h3 {
    width: 340px;
  }
}
@media (max-width: 380px) {
  .for-heading-container-4 h3 {
    width: 300px;
  }
}
