.sahil-card{
    width: 100%;
    height: 400px;
    background-color: red;
    display: flex;
    justify-content: center;
}
.w-sahil{
    width: 80%;
    height: 100%;
    display: flex;
    align-items: center;
}
.sahil-c-2{
    width: 300px;
    height: 300px;
    background-color: pink;
}