.reset-password-container{
    width: 100%;
    height: 480px;
    /* background-color: red; */
}
.inner-reset-password-conatiner{
    max-width: 1920px;
    height: 100%;
    background-color: #f7e5e5;
    margin: 0 auto;
    display: flex;
    justify-content: center;
}
.width-reset-password-conatiner{
    width: 80%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: pink; */
}
.width-reset-password-conatiner .gift-row-container{
    margin-left: 280px;
}
.r-password{
    margin-top: 30px;
}
/* 
@media(max-width:1035px){
    .width-reset-password-conatiner .gift-row-container{
        margin-left: 190px;
    }
}
@media(max-width:928px){
    .width-reset-password-conatiner .gift-row-container{
        margin-left: 140px;
    }
}
@media(max-width:804px){
    .width-reset-password-conatiner .gift-row-container{
        margin-left: 100px;
    }
} */