.foundation-container {
    max-width: 1050px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
  }

  .foundation-container-1{
    background-color: #f7e5e5;
  }
  
  .foundation-container h1 {
    text-align: center;
    margin-bottom: 20px;
    color: #630000;
  }
  
  .foundation-container p {
    margin-bottom: 15px;
    text-align: justify;
  }
  
  @media (max-width: 600px) {
    .foundation-container {
      padding: 10px;
      font-size: 14px;
    }
  }
  