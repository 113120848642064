.partner-form-container-s {
  width: 100%;
  min-height: 170px;
  background-color: #f7e5e5;
}

.inner-partner-form-conatainer-s {
  max-width: 1920px;
  height: 100%;
  background-color: #f7e5e5;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  color: #630000;
}

.width-i-p-f-c-s {
  width: 80%;
  padding-bottom: 20px;
}
.width-i-p-f-c-s h3 {
  font-size: 25px;
}
.partner-form-container-2-s {
  width: 100%;
  min-height: 500px;
  background-color: #f7e5e5;
}
.inner-partner-form-c-2 {
  max-width: 1920px;
  height: 100%;
  /* background-color: aqua; */
  margin: 0 auto;
  display: flex;
  justify-content: center;
}
.width-i-p-f-c-s-2 {
  width: 80%;
  height: 100%;
  display: flex;
  /* align-items: center; */
  gap: 70px;
  justify-content: center;
}
.left-p-f-c {
  width: 40%;
  height: 100%;
  /* background-color: #fff; */
}
.left-p-f-c .div-f input {
  width: 340px;
  color: #630000;
  border: 2px solid #630000;
}
.left-p-f-c .div-f .labelline {
  color: #630000;
  background-color: #f7e5e5;
}
.right-p-f-c .div-f input {
  width: 340px;
  color: #630000;
  border: 2px solid #630000;
}
.right-p-f-c {
  width: 40%;
  height: 100%;
  /* background-color: #cd1818; */
}
.right-p-f-c .search-bar-div-2 {
  width: 400px;
}
.right-p-f-c .show-555 {
  width: 360px;
}
.right-p-f-c .show-556 {
  width: 360px;
  margin-top: 0px;
  margin-left: 0px;
}

@media (max-width: 1034px) {
  .right-p-f-c .search-bar-div-2 {
    width: 360px;
  }
  .right-p-f-c .show-555 {
    width: 320px;
  }
  .right-p-f-c .show-556 {
    width: 320px;
  }
}
@media (max-width: 876px) {
  .right-p-f-c .show-556 {
    width: 300px;
  }
}
@media (max-width: 866px) {
  .right-p-f-c .search-bar-div-2 {
    width: 340px;
  }
  .right-p-f-c .show-555 {
    width: 300px;
  }
}
@media (max-width: 828px) {
  .right-p-f-c .search-bar-div-2 {
    width: 540px;
    margin-left: 50px;
  }
  .right-p-f-c .show-555 {
    width: 500px;
    margin-left: 50px;
  }
  .right-p-f-c .show-556 {
    width: 500px;
    margin-left: 50px;
  }
}
@media (max-width: 684px) {
  .right-p-f-c .show-556 {
    width: 420px;
  }
}
@media (max-width: 598px) {
  .right-p-f-c .show-556 {
    width: 320px;
  }
}
@media (max-width: 482px) {
  .right-p-f-c .show-556 {
    width: 320px;
    margin-left: 10px;
  }
}
/* @media (max-width:440px) {
  .right-p-f-c .show-556 {
    width: 320px;
    margin-left: 120px;
  }
} */

@media (max-width: 482px) {
  .right-p-f-c .show-556 {
    width: 320px;
    margin-left: 10px;
  }
}
@media (max-width: 682px) {
  .right-p-f-c .search-bar-div-2 {
    width: 460px;
    margin-left: 50px;
  }
  .right-p-f-c .show-555 {
    width: 420px;
    margin-left: 50px;
  }
}
@media (max-width: 598px) {
  .right-p-f-c .search-bar-div-2 {
    width: 360px;
    margin-left: 50px;
  }
  .right-p-f-c .show-555 {
    width: 320px;
    margin-left: 50px;
  }
}
@media (max-width: 482px) {
  .right-p-f-c .search-bar-div-2 {
    margin-left: 10px;
  }
  .right-p-f-c .show-555 {
    margin-left: 10px;
  }
}
@media (max-width: 440px) {
  .right-p-f-c .search-bar-div-2 {
    margin-left: -4px;
  }
  .right-p-f-c .show-556 {
    margin-left: -4px;
  }
}
@media (max-width: 426px) {
  .right-p-f-c .search-bar-div-2 {
    width: 310px;
    margin-left: 10px;
  }
  .right-p-f-c .show-556 {
    width: 270px;
    margin-left: 10px;
  }
}
.right-p-f-c .div-f .labelline {
  color: #630000;
  background-color: #f7e5e5;
}
.left-p-f-c .div-g {
  width: 360px;
  height: 60px;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
}

.right-p-f-c .div-g {
  width: 360px;
  height: 60px;
  /* background-color: red; */
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
}
.right-p-f-c .div-g {
  border: 2px solid #630000;
  color: #630000;
}
.left-p-f-c .div-g {
  border: 2px solid #630000;
  color: #630000;
}

@media (max-width: 1030px) {
  .left-p-f-c .div-f input {
    width: 320px;
  }
  .left-p-f-c .div-g {
    width: 340px;
  }
  .right-p-f-c .div-f input {
    width: 300px;
  }
  .right-p-f-c .div-g {
    width: 320px;
  }
  /* .width-i-p-f-c-s-2 {
    gap: 100px;
  } */
}
/* @media (max-width: 948px) {
  .width-i-p-f-c-s-2 {
    gap: 120px;
  }
} */
@media (max-width: 876px) {
  .left-p-f-c .div-f input {
    width: 280px;
  }
  .left-p-f-c .div-g {
    width: 300px;
    font-size: 12px;
  }
  .right-p-f-c .div-f input {
    width: 280px;
  }
  .right-p-f-c .div-g {
    width: 300px;
    font-size: 12px;
  }
}
@media (max-width: 830px) {
  .width-i-p-f-c-s-2,
  .w-i-p-f-c-4-s {
    /* align-items: center; */
    flex-direction: column;
    justify-content: center;
  }
  .left-p-f-c .div-f {
    margin-left: 50px;
    margin-top: 20px;
  }
  .left-p-f-c .div-f input {
    width: 480px;
  }
  .left-p-f-c .div-g {
    width: 500px;
    margin-left: 50px;
  }

  .right-p-f-c .div-f {
    margin-left: 50px;
    /* margin-top: -20px; */
  }
  .right-p-f-c .div-g {
    width: 500px;
    margin-left: 50px;
  }
  .right-p-f-c .div-f input {
    width: 480px;
  }
}

@media (max-width: 738px) {
  .left-p-f-c,
  .labelline {
    width: 160px;
  }
}
@media (max-width: 684px) {
  .left-p-f-c .div-f input {
    width: 400px;
  }
  .left-p-f-c .div-g {
    width: 420px;
    margin-left: 50px;
  }
  .right-p-f-c .div-f input {
    width: 400px;
  }
  .right-p-f-c .div-g {
    width: 420px;
    margin-left: 50px;
  }
 
}
@media (max-width: 598px) {
  .left-p-f-c .div-f input {
    width: 300px;
  }
  .left-p-f-c .div-g {
    width: 320px;
    margin-left: 50px;
  }
  .right-p-f-c .div-f input {
    width: 300px;
  }
  .right-p-f-c .div-g {
    width: 320px;
    margin-left: 50px;
  }
}

@media (max-width: 482px) {
  .left-p-f-c .div-g {
    margin-left: 10px;
  }
  .left-p-f-c .div-f {
    margin-left: 10px;
  }
  .right-p-f-c .div-g {
    margin-left: 10px;
  }
  .right-p-f-c .div-f {
    margin-left: 10px;
  }
}
@media (max-width: 442px) {
  .left-p-f-c .div-g {
    margin-left: -5px;
  }
  .left-p-f-c .div-f {
    margin-left: -5px;
  }
  .right-p-f-c .div-g {
    margin-left: -5px;
  }
  .right-p-f-c .div-f {
    margin-left: -5px;
  }
 
}
@media (max-width: 426px) {
  .left-p-f-c .div-f input {
    width: 250px;
    margin-left: 15px;
  }
  .left-p-f-c .div-g {
    width: 270px;
    margin-left: 10px;
  }
  .right-p-f-c .div-f input {
    width: 250px;
    margin-left: 15px;
  }
  .right-p-f-c .div-g {
    width: 270px;
    margin-left: 10px;
  }
}

.partner-form-container-3-s {
  width: 100%;
  min-height: 200px;
  background-color: #f7e5e5;
}
.partner-form-container-3-s-s-3 {
  width: 100%;
  min-height: 80px;
  background-color: #f7e5e5;
  /* background-color: red; */
}
.inner-p-f-c-3-s {
  max-width: 1920px;
  height: 100%;
  /* background-color: #fff; */
  margin: 0 auto;
  display: flex;
  justify-content: center;
}
.w-i-p-0002{
font-weight: 600;
  font-size: 10px;
}
.w-i-p-f-c-3-s {
  width: 80%;
  color: #630000;
}
.w-i-p-f-c-3-s p {
  font-size: 30px;
}
.w-i-p-f-c-3-s h3 {
  font-size: 25px;
}

.p-f-c-4-s {
  width: 100%;
  min-height: 200px;
  background-color: #f7e5e5;
}

.i-p-f-c-4-s {
  max-width: 1920px;
  height: 100%;
  /* background-color: #fff; */
  margin: 0 auto;
  display: flex;
  justify-content: center;
}
.w-i-p-f-c-4-s {
  width: 80%;
  display: flex;
  gap: 70px;
  justify-content: center;
}

@media (max-width: 550px) {
  .w-i-p-f-c-3-s p {
    font-size: 20px;
  }
  .w-i-p-f-c-4-s {
    gap: 20px;
  }
}

@media (max-width: 438px) {
  .right-p-f-c .show-555 {
    margin-left: -5px;
  }
}
@media (max-width: 426px) {
  .right-p-f-c .show-555 {
    width: 270px;
    margin-left: 10px;
  }
  .w-i-p-0002{
   
    font-size: 8px;
  }
}
