.div-six-container {
  width: 100%;
  min-height: 400px;
  background-color: #630000;
}
.inner-six-container {
  max-width: 1920px;
  height: 100%;
  /* background-color: aqua; */
  margin: 0 auto;
  display: flex;
  justify-content: center;
}
.w-six-container {
  width: 80%;
  height: 100%;
}
.for-heading h3 {
  font-size: 40px;
  color: #fff;
}
.sign-in-wrap-container {
  width: 100%;
  min-height: 200px;
  /* background-color: red; */
  display: flex;
}
.sign-in-left {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  /* background-color: #630000; */
}
.sign-in-right {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;

  /* background-color: green; */
}
@media (max-width: 850px) {
  .sign-in-wrap-container {
    flex-direction: column;
    align-items: center;
  }
  .for-heading h3 {
    text-align: center;
  }
  .sign-in-right {
    margin-top: -90px;
  }
}

@media (max-width: 560px) {
  .sign-in-left,
  .sign-in-right {
    margin-left: -90px;
  }
  .for-heading h3 {
    font-size: 25px;
  }
}
@media (max-width: 476px) {
  .sign-in-left,
  .sign-in-right {
    margin-left: -160px;
  }
}
@media (max-width: 436px) {
  .sign-in-left,
  .sign-in-right {
    margin-left: -190px;
  }
}
@media (max-width: 410px) {
  .sign-in-left,
  .sign-in-right {
    margin-left: -210px;
  }

}
@media (max-width: 398px) {
  /* .sign-in-left,
  .sign-in-right {
    margin-left: -160px;
  } */
  .for-heading h3 {
    font-size: 20px;
  }
}
@media (max-width: 1030px) {
  .sign-in-left .div-f input {
    width: 320px;
  }

  .sign-in-right .div-f input {
    width: 320px;
  }
}
@media (max-width: 972px) {
  .sign-in-wrap-container {
    gap: 40px;
  }
}
@media (max-width: 944px) {
  .sign-in-wrap-container {
    gap: 80px;
  }
}
@media (max-width: 914px) {
  .sign-in-wrap-container {
    gap: 100px;
  }
}
@media (max-width: 888px) {
  .sign-in-left .div-f input {
    width: 300px;
  }
  .sign-in-right .div-f input {
    width: 300px;
  }
}
