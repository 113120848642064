

/* .card-div{
    width: 300px;
    height: 300px;
    background-color: red;
} */
/* * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  } */
  
  body {
    /* display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: #353540; */
  }
  
  /* .l-container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
  
    width: 100%;
    max-width: 1200px;
  
  } */
  
  @media screen and (max-width: 760px) {
    .l-container {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  .b-game-card {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 300px;
    /* padding-bottom: 150%; */
    perspective: 1000px;
  }
  .b-game-card__cover {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-image: linear-gradient(120deg, #f6d365 0%, #fda085 100%);
    background-size: cover;
    perspective-origin: 50% 50%;
    transform-style: preserve-3d;
    transform-origin: top center;
    will-change: transform;
    transform: skewX(0.001deg);
    transition: transform 0.35s ease-in-out;
  }
  .b-game-card__cover::after {
    display: block;
    content: "";
    position: absolute;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100%;
    height: 140%;
    background: linear-gradient(226deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.4) 35%, rgba(255, 255, 255, 0.2) 42%, rgba(255, 255, 255, 0) 60%);
    transform: translateY(-20%);
    will-change: transform;
    transition: transform 0.65s cubic-bezier(0.18, 0.9, 0.58, 1);
  }
  .b-game-card:hover .b-game-card__cover {
    transform: rotateX(7deg) translateY(-10px);
  }
  .b-game-card:hover .b-game-card__cover::after {
    transform: translateY(0%);
  }
  .b-game-card::before {
    display: block;
    content: "";
    position: absolute;
    top: 5%;
    left: 5%;
    width: 90%;
    height: 90%;
    background: rgba(0, 0, 0, 0.5);
    box-shadow: 0 6px 12px 12px rgba(0, 0, 0, 0.4);
    will-change: opacity;
    transform-origin: top center;
    transform: skewX(0.001deg);
    transition: transform 0.35s ease-in-out, opacity 0.5s ease-in-out;
  }
  .b-game-card:hover::before {
    opacity: 0.6;
    transform: rotateX(7deg) translateY(-6px) scale(1.05);
  }