body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  
}
button{
  cursor: pointer;
}

/* ::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
  background-color: transparent;
}

::-webkit-scrollbar {
  width: 10px;
  height: 5px;
  background-color: #f5f5f5;
  background-color: transparent;

}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  height: 2px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);

  background-color: #630000;
} */


@media(max-width:500px){
  body{
    /* display: none; */
    overflow-x: hidden;
  }
}