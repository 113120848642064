.gift-form-container {
  width: 100%;
  min-height: 500px;
  background-color: red;
}
.inner-gift-form-container {
  max-width: 1920px;
  height: 100%;
  background-color: #f7e5e5;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.w-g-f-container {
  width: 30%;
  height: 90%;
  padding: 20px;
}
.gift-row-container {
  width: 350px;
}
.gift-entry-area {
  position: relative;
  height: 45px;
  line-height: 80px;
  margin-top: 40px;
  /* display: flex; */
}
.gift-entry-area input {
  position: absolute;
  width: 100%;
  height: 50px;
  outline: none;
  /* line-height: 80px; */
  background-color: transparent;
  transition: 0.1s ease;
  z-index: 111111;
  border-radius: 10px;
  padding: 0px 20px;
}
.gift-labelline {
  height: 40px;
  position: absolute;
  font-size: 18px;
  color: #630000;
  padding: 0 20px;
  line-height: 50px;
  background-color: #f7e5e5;
  /* background-color: red; */
  transition: 0.2s ease;
  margin-left: 20px;
  /* margin-top: -20px; */
}
.gift-entry-area input:focus,
.gift-entry-area input:valid {
  color: #630000;
  border: 2px solid #630000;
}
.gift-entry-area input:focus + .gift-labelline,
.gift-entry-area input:valid .gift-labelline {
  color: #630000;
  height: 30px;
  line-height: 30px;
  transform: translate(-15px, -16px) scale(0.88);
  z-index: 2452454;
}

.gift-row {
  width: 100%;
  height: 40px;
  /* background-color: red; */
  margin-top: 30px;
}
.gift-row-1 {
  height: 80px;
  display: flex;
  align-items: center;
}
.gift-row-1 input {
  margin-top: 5px;
}
.stick {
  /* background: red; */
  color: #630000;
  height: 30px;
  line-height: 30px;
  transform: translate(-15px, -16px) scale(0.88);
  z-index: 2452454;
}
.gift-row-1 button {
  width: 130px;
  height: 50px;
  background-color: #630000;
  color: #fff;
  font-size: 20px;
  border: none;
  border-radius: 10px;
  /* margin-top: -120px; */
}

.gift-confirm {
  width: 100%;
  height: 500px;
  background-color: #f7e5e5;
}
.inner-gift-confirm {
  max-width: 1920px;
  height: 100%;
  background-color: #f7e5e5;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.w-inner-gift-container {
  width: 60%;
  height: 80%;
  /* background-color: red; */
}
.w-inner-gift-container p {
  font-size: 50px;
  color: #630000;
}
/* .gift-entry-area input{
    width:;
} */

.gift-row-container .div-f input {
  width: 200px;
  border: 2px solid #630000;
}

@media (max-width: 780px) {
  .w-g-f-container {
    width: 50%;
    /* background-color: red; */
  }
}
@media (max-width: 624px) {
  .w-g-f-container {
    width: 60%;
    /* background-color: red; */
  }
}
@media (max-width: 518px) {
  .w-g-f-container {
    width: 70%;
    /* background-color: red; */
  }
}
@media (max-width: 450px) {
  .w-g-f-container {
    width: 80%;
    /* background-color: red; */
  }
}
@media (max-width: 394px) {
  .w-g-f-container {
    width: 90%;
    /* background-color: red; */
  }
}
@media (max-width: 482px) {
  .gift-entry-area input {
    width: 80%;
  }
}

.gift-row-1 input {
  margin-top: 64px;
}
@media (max-width: 880px) {
  .gift-row-1 input {
    margin-top: 45px;
  }
}
@media (max-width: 780px) {
  .gift-row-1 input {
    margin-top: 65px;
  }
}
@media (max-width: 708px) {
  .gift-row-1 input {
    margin-top: 43px;
  }
}
@media (max-width: 624px) {
  .gift-row-1 input {
    margin-top: 65px;
  }
}
@media (max-width: 584px) {
  .gift-row-1 input {
    margin-top: 45px;
  }
  .gift-row-container {
    margin-left: 0px;
  }
}
