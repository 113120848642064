.f-1 {
  width: 100%;
  height: 220px;
}
.inner-f-1 {
  max-width: 1920px;
  height: 100%;
  background-color: #630000;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  color: #fff;
}
.wraper-f-1 {
  width: 80%;
  display: flex;
  flex-direction: column;

  justify-content: center;
}
.input-wraper {
  display: flex;
  gap: 10px;
  align-items: center;
}

.form-container {
  width: 100%;
  height: 950px;
  background-color: #630000;
}
.inner-form-container {
  max-width: 1920px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.wraper-fo-c {
  width: 40%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
}
.div-g {
  width: 87%;
  height: 58px;
  border: 2px solid #fff;
  border-radius: 10px;
  padding: 0px 20px;
  color: #fff;
}
.div-f {
  position: relative;
  height: 80px;
  line-height: 60px;
}
.div-f input {
  position: absolute;
  width: 400px;
  height: 60px;
  outline: none;
  padding: 0 30px;
  line-height: 80px;
  border-radius: 10px;
  border: 2px solid #fff;
  background-color: transparent;
  transition: 0.1s ease;
  z-index: 1111;
  color: #fff;
}
.labelline {
  position: absolute;
  color: #fff;
  padding: 0px 15px;
  margin: 0 20px;
  background-color: #630000;
  transition: 0.2s ease;
}
.l-2 {
  background-color: #630000;
  color: #f7e5e5;
}
/* input:focus,
input:valid {
  border: 2px solid #fff;
} */

 /* .react-calendar{
  z-index: 29 !important;
 } */
.wraper-fo-c-2 input {
  border: 2px solid #f7e5e5;
  color: #f7e5e5;
}
input:focus + .labelline,
input:valid .labelline {
  color: #630000;
  line-height: 30px;
  transform: translate(-15px, -16px) scale(0.88);
  z-index: 99;
}
input:focus + .l-2,
input:valid .l-2 {
  color: #f7e5e5;
  line-height: 30px;
  transform: translate(-15px, -16px) scale(0.88);
  z-index: 99;
}

input:valid + .fix {
  color: #fff;
  line-height: 30px;
  transform: translate(-15px, -16px) scale(0.88);
  z-index: 999;
}
.ff input {
  color-scheme: dark;
}

.bank-detail-container {
  width: 100%;
  height: 150px;
  /* background-color: #001063; */
}
.inner-b-d-c {
  max-width: 1920px;
  height: 100%;
  background-color: #630000;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.w-inner-b-d-c {
  width: 83%;
}

.form-container-2 {
  width: 100%;
  height: 350px;
}
.inner-form-container-2 {
  max-width: 1920px;
  height: 100%;
  background-color: #630000;
  margin: 0 auto;
  display: flex;
  align-items: center;

  justify-content: center;
}
.f-2-heading-c {
  width: 80%;
  font-size: 25px;
  text-align: justify;
}
.wraper-fo-c-2 {
  width: 40%;
}

.submit-d-container {
  width: 100%;
  height: 80px;
}
.inner-s-d-c {
  max-width: 1920px;
  height: 100%;
  background-color: #630000;
  margin: 0 auto;
  text-align: center;
}

.inner-s-d-c button {
  background-color: #f7e5e5;
  border-radius: 100px;
  width: 150px;
  height: 40px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  color: #630000;
  cursor: pointer;
  display: inline-block;
  font-family: CerebriSans-Regular, -apple-system, system-ui, Roboto, sans-serif;
  text-align: center;
  text-decoration: none;
  transition: all 250ms;
  border: 0;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  font-size: 20px;
  margin-top: 20px;
}

.inner-s-d-c button:hover {
  transform: scale(1.05) rotate(-1deg);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.m-p-container {
  width: 100%;
  height: 30px;
}
.inner-m-p-container {
  max-width: 1920px;
  height: 100%;
  background-color: #630000;
  /* background-color: antiquewhite; */
  margin: 0 auto;
  display: flex;
  justify-content: center;
}
.width-container {
  width: 80%;
  color: #fff;
  display: flex;
  align-items: center;
}

@media (max-width: 974px) {
  .div-f input {
    width: 300px;
  }
  .div-g {
    width: 83%;
  }
}
@media (max-width: 918px) {
  .div-f input {
    width: 250px;
  }
  .div-g {
    width: 75%;
  }
}

.error-message {
  color: red;
  font-size: 12px;
  margin-left: 4px;
  margin-top: -12px;
}

@media (max-width: 764px) {
  .la-2 {
    width: 140px;
  }
  .error-message {
    margin-left: 52px;
  }
}
@media (max-width: 720px) {
  .error-message {
    width: 160px;
    /* background-color: #630000; */
    margin-left: 52px;
  }
}
@media (max-width: 482px) {
  .error-message {
    margin-left: 12px;
  }
}
@media (max-width: 442px) {
  .error-message {
    margin-left: -2px;
  }
}
@media (max-width: 414px) {
  .error-message {
    margin-left: 12px;
  }
  .ll-2 {
    width: 110px;
    /* margin-left: 20px; */
    background-color: #630000;
  }
}

.em-2 {
  margin-left: 10px;
  width: 180px;
}

@media (max-width: 384px) {
  .left-p-f-c,
  .right-p-f-c {
    margin-left: -20px;
  }
}

.p-hide {
  display: none;
}
.div-d {
  width: 300px;
  height: 40px;
  /* border: 2px solid #630000; */
  /* background-color: #630000; */
  margin-bottom: 50px;
  border-radius: 5px;
}
.date-picker {
  width: 36px;
  height: 50px;
  border-radius: 5px;
  padding: 0px 30px;
  /* border: 2px solid #630000; */
  border: none;
  background: none;
  color: #630000;
}
.date-pick-2 {
  width: 405px;
  height: 65px;
  /* padding: 10px 20px; */
  background-color: red;
  outline: none;
  border: 2px solid #630000;
  background-color: transparent;
  z-index: 129747 !important;
  border-radius: 10px;
}
/* .react-calendar {
  z-index: 12974522347 !important;
} */
.zindex {
  z-index: 4;
}
/* .date-pick-2:focus {
  border: 2px solid #630000;
} */
.fa-calendar {
  margin-left: 350px;
  margin-top: 15px;
}

.div-fff {
  width: 400px;
  height: 80px;
  border: none;
  /* background-color: #630000; */
}
.date-pick {
  width: 405px;
  border: none;
  /* height: 400px; */
  border: 2px solid #630000;
  border-radius: 10px;
  height: 65px;
}
.react-date-picker__wrapper {
  border: none !important;
  /* border: thin solid gray; */
}

@media (max-width: 1036px) {
  .date-pick-2 {
    width: 383px;
  }
}
@media (max-width: 876px) {
  .date-pick-2 {
    width: 343px;
  }
}
@media (max-width: 830px) {
  .div-fff {
    width: 405px;
    margin-left: 48px;
    /* background-color: red; */
    margin-top: 20px;
  }
  .date-pick-2 {
    width: 545px;
  }
}
@media (max-width: 684px) {
  .date-pick-2 {
    width: 465px;
  }
}
@media (max-width: 598px) {
  .date-pick-2 {
    width: 367px;
  }
  .fixxx {
    width: 100px;
  }
}
@media (max-width: 480px) {
  .div-fff {
    width: 360px;
  }
}
@media (max-width: 482px) {
  .div-fff {
    margin-left: 8px;
    /* background-color: red; */
    margin-top: 20px;
  }
}
@media (max-width: 442px) {
  .div-fff {
    margin-left: -8px;
    /* background-color: red; */
    margin-top: 20px;
  }
}
@media (max-width: 426px) {
  .div-fff {
    width: 320px;
    margin-left: 5px;
    /* background-color: red; */
    margin-top: 20px;
  }
  .date-pick-2 {
    width: 318px;
  }
}
.react-date-picker {
  padding: 20px;
}

/* .react-date-picker__calendar .react-calendar {
  z-index: 35234523532235 !important;
}
.react-datepicker-popper {
  z-index: 99992342142 !important;
}
.react-date-picker__inputGroup__input .react-date-picker__inputGroup__month {
  margin-left: 40px !important;
} */

.react-calendar__tile--hasActive {
  background: none !important;
}
.react-calendar__tile--hasActive {
  background: none !important;
}
.react-calendar__tile--now {
  background: none !important;
}
