.signIn-Page-div {
  width: 100%;
  height: 500px;
  /* background-color: aqua; */
}
.inner-signIn-Pge {
  max-width: 1920px;
  height: 100%;
  background-color: beige;
  /* background-image: url("../Images/Edu\ BG\ 1.png"); */
  /* background-image: url("../Images/online-classes.png"); */
  background-image: url("../Images/sign-in.jpeg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.w-inner-signIn-page {
  width: 80%;
}
.box-sign-container {
  width: 35%;
  height: 95%;
  background-color: rgba(13, 14, 12, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.box-sign-container h3 {
  font-size: 40px;
  color: #fff;
}
.btn-input-div button {
  width: 374px;
  height: 40px;
  font-size: 25px;
  border-radius: 5px;
  border: none;
  background-color: #f7e5e5;
  color: #630000;
  cursor: pointer;
}
.margin {
  margin-left: 80px;
}
.input-div-conateirn p {
  margin-left: -0px;
  font-size: 18px;
  color: #fff;
}

.lg {
  background-color: transparent;
}
.input-div-conateirn {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}
.inner-input-div {
  width: 380px;
  /* background-color: red; */
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.inner-input-div input {
  /* width: 400px; */
  height: 30px;
  border-radius: 5px;
  padding: 10px;
  background-color: #1c2432;
  border: none;
  color: #fff;
}

.span {
  font-weight: 900;
}

@media (max-width: 1030px) {
  .inner-input-div {
    align-items: center;
  }
  .inner-input-div input {
    width: 320px;
  }
  .btn-input-div button {
    width: 340px;
  }
}
@media (max-width: 872px) {
  .inner-input-div input {
    width: 300px;
  }
  .btn-input-div button {
    width: 320px;
  }
}
@media (max-width: 800px) {
  .box-sign-container {
    width: 60%;
  }
}
@media (max-width: 782px) {
  .inner-input-div input {
    width: 280px;
  }
  .btn-input-div button {
    width: 300px;
  }
}
@media (max-width: 728px) {
  .inner-input-div input {
    width: 260px;
  }
  .btn-input-div button {
    width: 280px;
  }
}
@media (max-width: 696px) {
  .box-sign-container {
    width: 53%;
  }
}
@media (max-width: 672px) {
  .box-sign-container {
    width: 60%;
  }
}
@media (max-width: 594px) {
  .box-sign-container {
    width: 60%;
  }
}
@media (max-width: 510px) {
  .box-sign-container {
    width: 70%;
  }
}
@media (max-width: 482px) {
  .box-sign-container {
    width: 80%;
  }
}
@media (max-width: 410px) {
  .box-sign-container {
    width: 90%;
  }
}
@media (max-width: 376px) {
  .box-sign-container {
    width: 95%;
  }
}
@media (max-width: 354px) {
  .box-sign-container {
    width: 100%;
  }
}
@media (max-width: 980px) {
  .inner-input-div input {
    width: 260px;
  }
  .btn-input-div button {
    width: 280px;
  }
}
@media (max-width: 850px) {
  .inner-input-div input {
    width: 240px;
  }
  .btn-input-div button {
    width: 260px;
  }
}

.radio-btn {
  width: 100%;
  height: 50px;
  /* background-color: red; */
  text-align: center;
  color: #fff;
}
